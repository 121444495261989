
import ABLogo from "../../../assets/img/sponsors/angelbeach.png";
import HNBALogo from "../../../assets/img/sponsors/hnba.png";
import UberLogo from "../../../assets/img/sponsors/uber.png";
import MalithLogo from "../../../assets/img/sponsors/malith.png";
import DigiworldLogo from "../../../assets/img/sponsors/digiworld.png";
import PadmikaLogo from "../../../assets/img/sponsors/padmika.png";
import SampathLogo from "../../../assets/img/sponsors/sampath.png";
import HonorLogo from "../../../assets/img/sponsors/honor.png";
import KodakLogo from "../../../assets/img/sponsors/kodak.png";
import NuwanLogo from "../../../assets/img/sponsors/nuwan.png";
import SidaraLogo from "../../../assets/img/sponsors/sridara.png";
import PradeepLogo from "../../../assets/img/sponsors/pradeep.png";

const sponsorsList = [
    {
        "company": "Uber",
        "logo": UberLogo,
        "category": "platinumPlus",
        "url": "https://www.uber.com/lk/en/"
    },
    {
        "company": "Malith Jayarathna | Chemistry",
        "logo": MalithLogo,
        "category": "silver",
        "url": "https://web.facebook.com/malithchemistry/?_rdc=1&_rdr#"
    },
    {
        "company": "Sampath Bank",
        "logo": SampathLogo,
        "category": "bronze",
        "url": "https://www.sampath.lk/"
    },
    {
        "company": "SINGER Sri Lanka",
        "logo": HonorLogo,
        "category": "bronze",
        "url": "https://www.singersl.com/"
    },
    {
        "company": "HNB Assurance",
        "logo": HNBALogo,
        "category": "bronze",
        "url": "https://www.hnbassurance.com/"
    },
    {
        "company": "Nuwan Chathuranga",
        "logo": NuwanLogo,
        "category": "bronze",
        "url": "https://web.facebook.com/p/Nuwan-Chathuranga-100005520257629/?_rdc=1&_rdr#"
    },
    {
        "company": "Pradeep Dharshana - ET",
        "logo": PradeepLogo,
        "category": "bronze",
        "url": "https://www.youtube.com/c/PradeepDarshanaET"
    },
    {
        "company": "DigiWorld Advertising",
        "logo": DigiworldLogo,
        "category": "small",
        "url": "https://www.facebook.com/digiworldgalle/"
    },
    {
        "company": "Padmika Godakanda | Physics",
        "logo": PadmikaLogo,
        "category": "small",
        "url": "https://www.vidarsha.lk/"
    },
    {
        "company": "Sridara Pharmacy",
        "logo": SidaraLogo,
        "category": "small",
        "url": "#"
    },
    {
        "company": "Kodak",
        "logo": KodakLogo,
        "category": "small",
        "url": "https://www.kodak.com/en/"
    }
];

export default sponsorsList;