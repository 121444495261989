import React, { useState, useEffect } from "react";
import "./index.css";
import ScoreBlock from "./components/ScoreBlock";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import BattingCard from "./components/BattingCard";
import BowlingCard from "./components/BowlingCard";
import axios from "axios";
import NavBar from "../../common/NavBar";
// import AdBanner from "../../common/AdBanner";
// import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ScoreCardPage = () => {

    const [mobileMenu, setMobileMenu] = useState(false);
    const [highlights, setHighlights] = useState([]);
    const [scorecard, setScorecard] = useState();
    const [ads, setAds] = useState([]);

    const getAds = () => {
        axios
            .get(process.env.REACT_APP_ADS_URL)
            .then((res) => {
                if (res.data) {
                    setAds(res.data);
                    showToast(ads[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getScorecard = () => {
        axios
            .get(process.env.REACT_APP_SCORECARD_URL)
            .then((res) => {
                if (res.data) {
                    setScorecard(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getScorecard();
        getAds();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomAd = ads[Math.floor(Math.random() * ads.length)];
            showToast(randomAd);
        }, process.env.REACT_APP_AD_INTERVAL);
        return () => clearInterval(interval);
    }, [ads]);

    // const showToast = (ad) => {
    //     console.log(ad)
    //     if (ad.center !== true) {
    //         toast(
    //             <AdBanner image={ad.imageURL} url={ad.url} />,
    //             {
    //                 closeOnClick: true,
    //                 type: 'success',
    //                 autoClose: true
    //             });
    //     }
    // }

    const getScorecardHeading = (inning) => {
        if(process.env.REACT_APP_ONE_DAY_MATCH === "true") {
            return getFullTeamName(inning.battingTeam);
        } else {
            return inning.battingTeam + " " + inning.innings;
        }
    }

    const getFullTeamName = (team) => {
        if(team === "RCG") {
            return "Richmond College";
        } else {
            return "Mahinda College";
        }
    }

    const getCurrentYear = () => {
        return new Date().getFullYear().toString();
      }


    return (
        <section className="score-card-page">
            <NavBar />
            <div className="score-card-main has-text-centered mt-4">
                <h1 className="title is-2 has-text-white" data-aos="fade-up" data-aos-duration="900">Full Score Card</h1>
                <ScoreBlock />
            </div>
            <div className="score-accordians-section">
                <h1 className="title is-4 has-text-white" data-aos="zoom-in" data-aos-duration="900">Scorecards by Innings</h1>
                <div className="accordians">
                    <Accordion className="accordian-main">
                        {scorecard &&
                            scorecard.map((inning, index) => {
                                return (
                                    <AccordionItem className="mb-4" key={index} data-aos="fade-up" data-aos-duration="900">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {getScorecardHeading(inning)}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <BattingCard
                                                batters={inning.batters}
                                                score={inning.score}
                                                rr={inning.runRate}
                                                overs={inning.overs}
                                            />
                                            <BowlingCard bowlers={inning.bowlers} />
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                );
                            })}
                    </Accordion>
                </div>
            </div>
            <div className="notification ml-6 mr-6 mt-6 has-blue-background scorecard-notification">
                <button className="delete"></button>
                <strong>⚠️ &nbsp; &nbsp; Please rotate your device to view the full score cards.</strong>
            </div>
            <div className="rl-footer has-text-centered pb-4 pt-6">
                <div> Designed and Developed by RL © {getCurrentYear()}</div>
            </div>
            {/* <ToastContainer
                position="bottom-center"
                theme="dark"
                pauseOnFocusLoss={false}
                pauseOnHover={true}
            /> */}
        </section>
    )
}

export default ScoreCardPage;