import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./screens/HomePage";
import ScoreCardPage from "./screens/ScoreCardPage";
import BlogsPage from "./screens/BlogsPage";
import AOS from "aos";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/scorecard" exact>
          <ScoreCardPage />
        </Route>
        {/* <Route path="/blog" exact>
          <BlogsPage />
        </Route> */}
      </Switch>
    </Router>
  );
};

export default App;
