import React, { useState } from "react";
import "./index.css";

const NavBar = () => {
  const [isActive, setisActive] = useState(false);
  const isMatchStarted = process.env.REACT_APP_MATCH_STARTED === "true";

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-start">
          <a className="navbar-item" href="/">
            Home
          </a>
          <a
            className="navbar-item"
            href="https://www.youtube.com/@RichmondLive-RL/streams"
            target="_blank"
          >
            Live
          </a>
          {isMatchStarted && (
            <a className="navbar-item" href="/scorecard">
              Scorecard
            </a>
          )}
          <a className="navbar-item" href="https://richmondlive.medium.com/" target="_blank">
            Blogs
          </a>
          <a className="navbar-item" href="#sponsors-section">
            Sponsors
          </a>
        </div>
        <div className="navbar-end">
          {!isActive && (
            <>
              <a className="navbar-item" href="https://www.facebook.com/share/1GvHsvHs2Y/" target="_blank">
                <i className="fab fa-facebook-square navbar-icon"></i>
              </a>
              <a className="navbar-item" href="https://x.com/LiveRichmond/status/1895644459486077407" target="_blank">
                <i className="fab fa-twitter navbar-icon"></i>
              </a>
              <a className="navbar-item" href="https://www.instagram.com/richmondlive.rl?igsh=MTM3d3JreWMwd2w5ag==" target="_blank">
                <i className="fab fa-instagram navbar-icon"></i>
              </a>
              <a className="navbar-item" href="https://youtube.com/@richmondlive-rl?si=APiMa-3mhnRRRVRl" target="_blank">
                <i className="fab fa-youtube navbar-icon"></i>
              </a>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
